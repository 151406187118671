<template>
  <div class="era-postion-container">
    <div class="era-postion-head">
      <p>开始计算</p>
      <p>开始模拟</p>
      <p>开始探索</p>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "JoinUs",
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
.era-postion-container {
  .era-postion-head {
    background: url("../assets/images/openpositions-01.png") no-repeat center
      0px;
    background-position: center 0px;
    background-size: cover;
    width: 100%;
    height: 25vw;
    display: flex;
    p {
      color: rgb(255, 255, 255);
      font-family: 思源黑体 CN;
      font-size: 3.6vw;
      font-weight: 550;
      line-height: 120px;
      letter-spacing: 0.3vw;
      padding: 7.2vw 8.6vw;
    }
  }
}
</style>
